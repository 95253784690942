import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import InvestmentItems from '../components/Investment/InvestmentItems'
import { useTranslation } from 'react-i18next'

const Investment = () => {
    const { t } = useTranslation();
    return (
        <>
            <SubpageBanner title={t("investmenttitle")} />
            <InvestmentItems />
        </>
    )
}

export default Investment