import React, { useEffect } from 'react'
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

const PromotionDetailItem = ({ location }) => {
    const { t } = useTranslation()
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const { advantages } = location.state || {};

    return (
        <div className='promotion-detail-item-content'>
            <div className="container">
                <div className="row gx-lg-5 gx-0">
                    <div className="col-lg-6" data-aos="fade-right">
                        <h3 className='h2 mb-4'>{location.state.title}</h3>
                        {location.state.texts.map((e) => (
                            <p className="mb-3">
                                {e}
                            </p>
                        ))}
                        <div className="mt-5">
                            {advantages && advantages.length > 0 && (
                                <div className="advantages-list">
                                    {advantages.map((adv, index) => (
                                        <div key={index} className="advantage-item mb-4">
                                            <h5 className='mb-2'>{adv.title}</h5>
                                            <p className='mb-0'>{adv.text}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-left">
                        <img src={location.state.img} alt={location.state.title} width="100%" className='rounded' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionDetailItem