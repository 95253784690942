import React, { useEffect } from 'react'
import Customer from "../assets/icons/customer.svg"
import Reliability from "../assets/icons/reliability.svg"
import Improve from "../assets/icons/improve.svg"
import Image from "../assets/img/banner.jpg"
import AOS from 'aos';
import { useTranslation } from 'react-i18next'

const HomepageAbout = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className="homepage-about-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 pe-lg-5">
                        <h3 className='homepage-section-title mb-3' data-aos="fade-right">{t("aboutcompany")}</h3>
                        <p className="mb-0 homepage-section-text" data-aos="fade-right" data-aos-duration="1200">
                            {t("aboutcompanytext")}
                        </p>
                        <div className="mt-5" data-aos="fade-right">
                            <div className="homepage-about-image rounded shadow-sm" style={{ backgroundImage: `url(${Image})` }}></div>
                            {/* <img src={Image} alt="" width="100%%" /> */}
                        </div>
                    </div>
                    <div className="col-lg-5 text-end mt-lg-0 mt-md-0 mt-5">
                        <div className="homepage-about-card text-start shadow-sm mb-3" data-aos="fade-left">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="homepage-about-icon mb-4">
                                        <img src={Customer} alt="customer-icon" width="42px" />
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <h5 className='mb-2' style={{ fontSize: 18 }}>{t("aboutcompanytitle1")}</h5>
                                    <p className="mb-0" style={{ fontSize: 14 }}>
                                        {t("aboutcompanytext1")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="homepage-about-card text-start shadow-sm mb-3" data-aos="fade-left">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="homepage-about-icon mb-4">
                                        <img src={Reliability} alt="customer-icon" width="42px" />
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <h5 className='mb-2' style={{ fontSize: 18 }}>{t("aboutcompanytitle2")}</h5>
                                    <p className="mb-0" style={{ fontSize: 14 }}>
                                        {t("aboutcompanytext2")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="homepage-about-card text-start shadow-sm" data-aos="fade-left">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="homepage-about-icon mb-4">
                                        <img src={Improve} alt="customer-icon" width="42px" />
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <h5 className='mb-2' style={{ fontSize: 18 }}>{t("aboutcompanytitle3")}</h5>
                                    <p className="mb-0" style={{ fontSize: 14 }}>
                                        {t("aboutcompanytext3")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageAbout