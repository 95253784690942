import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask'
import { ToastContainer, toast } from 'react-toastify';

const FixedRegisterForm = () => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [formUrl, setFormUrl] = useState();
    useEffect(() => {
        if (window.location.origin.includes("localhost")) {
            setFormUrl(
                "https://nova.testmedici.com/api/registration/client/Registration/RegisterUser"
            );
        } else if (window.location.origin.includes("test")) {
            setFormUrl(
                "https://nova.testmedici.com/api/registration/client/Registration/RegisterUser"
            );
        } else {
            //canlı domain
            setFormUrl(
                window.location.origin.replace("pro", "nova") +
                "/api/registration/client/Registration/RegisterUser"
            );
        }
    }, [])
    const [isActive, setIsActive] = useState(false)
    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            phone: '',
            password: '',
            repassword: '',
            birthDate: '',
            identity: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('İsim gerekli')),
            surname: Yup.string().required(t('Soyisim gerekli')),
            email: Yup.string().email(t('Geçersiz e-posta')).required(t('E-posta gerekli')),
            phone: Yup.string()
                .required(t('Telefon numarası gerekli')),
            password: Yup.string()
                .min(6, t('Parola en az 6 karakter olmalı'))
                .required(t('Parola gerekli')),
            repassword: Yup.string()
                .oneOf([Yup.ref('password'), null], t('Parolalar eşleşmiyor'))
                .required(t('Parola onayı gerekli')),
            birthDate: Yup.date().required(t('Doğum tarihi gerekli')),
            // identity: Yup.string()
            //     .length(11, t('TC Kimlik numarası 11 karakter olmalı'))
            //     .matches(/^[0-9]+$/, t('Sadece sayılar girin'))
            //     .required(t('TC Kimlik numarası gerekli')),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true)

            try {
                const data = {
                    FirstName: values.name,
                    LastName: values.surname,
                    Email: values.email,
                    Phone: String(values.phone).replace("(", "").replace(")", "").replaceAll(" ", ""),
                    Password: values.password,
                    IdentityNo: values.identity,
                    dateOfBirth: values.birthDate,
                    referredBy: ""
                };

                const response = await axios.post(formUrl, data);
                toast.success(t('Kayıt işlemi başarıyla tamamlandı. Giriş bilgileriniz e-posta adresinize iletişmiştir.'));

                formik.resetForm();
                setIsSubmitting(false)
            } catch (error) {
                const errorMessage = error.response.data.message;

                if (errorMessage.includes("Phone Number or Email Address")) {
                    toast.error(t('Bu e-posta adresi ya da telefon numarası zaten kullanılıyor. Lütfen bilgileri kontrol edin.'));
                }
                else if (errorMessage.includes("Email already in use !!")) {
                    toast.error(t("Bu e-posta adresi zaten kullanılıyor."))
                }
                else if (errorMessage.includes("Phone Number is already in use ..")) {
                    toast.error(t("Bu telefon numarası zaten kullanılıyor."))
                }
                else if (errorMessage.includes("Unexpected character encountered")) {
                    toast.error(t("Parolanız geçersiz. Lütfen parolanızda en az bir harf ve bir rakam kullanın."))
                }
                else {
                    toast.error(t("Beklenmedik bir hata oluştu. Lütfen tekrar deneyin."))
                }
                setIsSubmitting(false)
            }
        }
    });

    const activeHandle = () => {
        setIsActive(true)
    }
    const passiveHandle = () => {
        setIsActive(false)
    }

    return (
        <>
            <div className='fixed-register-form-content-btn w-100'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <button className='btn btn-primary-v2 w-100' onClick={activeHandle}>
                                Hemen Hesap Oluştur
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`fixed-register-form-content shadow ${isActive ? "" : "d-none"}`}>
                <form className='py-5' onSubmit={formik.handleSubmit}>
                    <div className='row justify-content-center'>
                        <div className="col-lg-8">
                            <div className='d-flex align-items-start justify-content-between'>
                                <div>
                                    <h4>Hesap oluştur</h4>
                                    <p>Nova Invest Güçlü Bir Yatırımın Anahtarı!</p>
                                </div>
                                <div style={{ cursor: "pointer" }} onClick={passiveHandle}>
                                    <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/787878/delete-sign.png" alt="delete-sign" />
                                </div>
                            </div>
                            <div className='row gx-lg-5 mt-4'>
                                <div className="col-lg-6">
                                    <div>
                                        <label htmlFor="name">{t("İsminiz")} <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            id='name'
                                            placeholder={t('İsminizi buraya girin')}
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-0 mt-md-0 mt-3">
                                    <div>
                                        <label htmlFor="surname">{t("Soyisminiz")} <span className='text-danger'>*</span></label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            id='surname'
                                            placeholder={t('Soyisminizi buraya girin')}
                                            {...formik.getFieldProps('surname')}
                                        />
                                        {formik.touched.surname && formik.errors.surname ? <div className="text-danger">{formik.errors.surname}</div> : null}
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                    <div>
                                        <label htmlFor="email">{t("E-posta Adresiniz")} <span className='text-danger'>*</span></label>
                                        <input
                                            type="email"
                                            className='form-control'
                                            id='email'
                                            placeholder={t('E-posta adresinizi buraya girin')}
                                            {...formik.getFieldProps('email')}
                                        />
                                        {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                    <div>
                                        <label htmlFor="phone">{t("Telefon Numaranız")} <span className='text-danger'>*</span></label>
                                        <MaskedInput
                                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                            className='form-control'
                                            id='phone'
                                            placeholder={t('Telefon numaranızı buraya girin')}
                                            {...formik.getFieldProps('phone')}
                                        />
                                        {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null}
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                    <div>
                                        <label htmlFor="password">{t("Parolanız")} <span className='text-danger'>*</span></label>
                                        <input
                                            type="password"
                                            className='form-control'
                                            id='password'
                                            placeholder={t('Parolanızı buraya girin')}
                                            {...formik.getFieldProps('password')}
                                        />
                                        {formik.touched.password && formik.errors.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                    <div>
                                        <label htmlFor="repassword">{t("Parola Onayı")} <span className='text-danger'>*</span></label>
                                        <input
                                            type="password"
                                            className='form-control'
                                            id='repassword'
                                            placeholder={t('Parolanızı tekrar girin')}
                                            {...formik.getFieldProps('repassword')}
                                        />
                                        {formik.touched.repassword && formik.errors.repassword ? <div className="text-danger">{formik.errors.repassword}</div> : null}
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                    <div>
                                        <label htmlFor="birthDate">{t("Doğum Tarihiniz")} <span className='text-danger'>*</span></label>
                                        <input
                                            type="date"
                                            className='form-control'
                                            id='birthDate'
                                            placeholder={t('Doğum tarihinizi girin')}
                                            {...formik.getFieldProps('birthDate')}
                                        />
                                        {formik.touched.birthDate && formik.errors.birthDate ? <div className="text-danger">{formik.errors.birthDate}</div> : null}
                                    </div>
                                </div>
                                <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                    <div>
                                        <label htmlFor="identity">{t("TC Kimlik Numaranız")}</label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            id='identity'
                                            maxLength={11}
                                            placeholder={t('TC kimlik numaranızı girin')}
                                            {...formik.getFieldProps('identity')}
                                        />
                                        {/* {formik.touched.identity && formik.errors.identity ? <div className="text-danger">{formik.errors.identity}</div> : null} */}
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <button className='btn btn-primary-v2 w-100 py-2' type='submit' disabled={isSubmitting}>
                                        {isSubmitting ? t("sending") : t("register")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </form>
            </div>
        </>
    )
}

export default FixedRegisterForm