import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
import SubpageBanner from "../components/SubpageBanner"
import PromotionDetailItem from '../components/Promotions/PromotionDetailItem';
import { useTranslation } from 'react-i18next';

const PromotionDetail = () => {
    const { t } = useTranslation();
    const { title } = useParams();
    const location = useLocation();

    return (
        <>
            <SubpageBanner title={t("promotionsdetail")} />
            <PromotionDetailItem location={location} />
        </>
    )
}

export default PromotionDetail