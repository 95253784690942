import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import ContactForm from '../components/Contact/ContactForm'
import { useTranslation } from 'react-i18next'

const Contact = () => {
    const { t } = useTranslation();
    return (
        <>
            <SubpageBanner title={t("İletişim")} />
            <ContactForm />
        </>
    )
}

export default Contact