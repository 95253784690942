import React from 'react'
import Banner from '../components/Banner'
import HomepageAbout from '../components/HomepageAbout'
import HomepageGlobal from '../components/HomepageGlobal'
import HomepageWhyNova from '../components/HomepageWhyNova'
import HomepageCustomerReview from '../components/HomepageCustomerReview'
import CTA from '../components/CTA'
import PriceFlow from '../components/PriceFlow'
import FixedRegisterForm from "../components/FixedRegisterForm"

const Homepage = () => {
    return (
        <>
            <Banner />
            <PriceFlow />
            <HomepageAbout />
            <HomepageGlobal />
            <HomepageWhyNova />
            <HomepageCustomerReview />
            <CTA />
            <FixedRegisterForm />
        </>
    )
}

export default Homepage