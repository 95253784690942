import React, { useEffect } from 'react'
import Sigorta from "../../assets/img/sigorta.png"
import Doviz from "../../assets/img/doviz-kuru.png"
import Takvim from "../../assets/img/takvim.png"
import Vip from "../../assets/img/vip.jpeg"
import ArkadasiniGetir from "../../assets/img/arkadasini-getir.png"
import YatirimDestekcisi from "../../assets/img/yatirim-destekcisi.png"
import DemoHesap from "../../assets/img/demo-hesap.png"
import { Link } from 'react-router-dom'
import Shape from "../../assets/icons/shape.svg"
import AOS from 'aos';
import { useTranslation } from 'react-i18next'

const PromotionsItems = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const data = [
        {
            img: Sigorta,
            title: t("promotionsitemtitle1"),
            text1: t("promotionsitem1text1"),
            text2: t("promotionsitem1text2"),
            text3: t("promotionsitem1text3")
        },
        {
            img: Doviz,
            title: t("promotionsitemtitle2"),
            text1: t("promotionsitem2text1"),
            text2: t("promotionsitem2text2"),
            text3: t("promotionsitem2text3")
        },
        {
            img: Takvim,
            title: t("promotionsitemtitle3"),
            text1: t("promotionsitem3text1"),
            text2: t("promotionsitem3text2"),
            text3: ""
        },
        {
            img: Vip,
            title: t("promotionsitemtitle4"),
            text1: t("promotionsitem4text1"),
            text2: t("promotionsitem4text1"),
            text3: "",
            advantagestitle1: t("promotionsitem4advantages1title"),
            advantagestext1: t("promotionsitem4advantages1text"),
            advantagestitle2: t("promotionsitem4advantages2title"),
            advantagestext2: t("promotionsitem4advantages2text"),
            advantagestitle3: t("promotionsitem4advantages3title"),
            advantagestext3: t("promotionsitem4advantages3text"),
            advantagestitle4: t("promotionsitem4advantages4title"),
            advantagestext4: t("promotionsitem4advantages4text"),
            advantagestitle5: t("promotionsitem4advantages5title"),
            advantagestext5: t("promotionsitem4advantages5text"),
        },
        {
            img: ArkadasiniGetir,
            title: t("promotionsitemtitle5"),
            text1: t("promotionsitem5text1"),
            text2: "",
            text3: ""
        },
        {
            img: YatirimDestekcisi,
            title: t("promotionsitemtitle6"),
            text1: t("promotionsitem6text1"),
            text2: "",
            text3: "",
            advantagestitle1: t("promotionsitem6advantages1title"),
            advantagestext1: t("promotionsitem6advantages1text"),
            advantagestitle2: t("promotionsitem6advantages2title"),
            advantagestext2: t("promotionsitem6advantages2text")
        },
        {
            img: DemoHesap,
            title: t("promotionsitemtitle7"),
            text1: t("promotionsitem7text1"),
            text2: "",
            text3: "",
            advantagestitle1: t("promotionsitem7advantages1title"),
            advantagestext1: t("promotionsitem7advantages1text"),
            advantagestitle2: t("promotionsitem7advantages2title"),
            advantagestext2: t("promotionsitem7advantages2text")
        },
    ]
    return (
        <div className='promotions-content'>
            <div className="container">
                <div className="row">
                    {
                        data.map((e, index) => (
                            <div className="col-lg-4" key={index} data-aos="zoom-in">
                                <Link to={`/promotion/${e.title}`} state={{
                                    img: e.img, title: e.title, texts: [e.text1, e.text2, e.text3], advantages: [
                                        { title: e.advantagestitle1, text: e.advantagestext1 },
                                        { title: e.advantagestitle2, text: e.advantagestext2 },
                                        { title: e.advantagestitle3, text: e.advantagestext3 },
                                        { title: e.advantagestitle4, text: e.advantagestext4 },
                                        { title: e.advantagestitle5, text: e.advantagestext5 }
                                    ]
                                }} className='text-dark'>
                                    <div className="promotions-card position-relative">
                                        <img src={e.img} alt="promotion" width="100%" className='px-3' />
                                        <img src={Shape} alt="promotions-overlay" className='promotions-overlay' />
                                        <div className='promotions-card-title shadow'>
                                            {e.title}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PromotionsItems