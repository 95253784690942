import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import Chevron from "../assets/icons/subpage-chevron.svg"
import Banner from "../assets/img/subpage-banner.svg"
import { useTranslation } from 'react-i18next';

const SubpageBanner = ({ title }) => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className='subpage-banner-content' style={{ backgroundImage: `url(${Banner})` }}>
            <div className="container h-100">
                <div className="d-flex align-items-center justify-content-center h-100 position-relative">
                    <h3 className='subpage-banner-title' data-aos="zoom-in-down">
                        {title}
                    </h3>
                    <div className="subpage-banner-pagination" data-aos="zoom-in-right">
                        <Link to="/">{t("Ana Sayfa")} <img src={Chevron} alt="chevron" width="6px" className='mx-2' /> </Link> {title}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubpageBanner