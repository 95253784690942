import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import PromotionsItems from '../components/Promotions/PromotionsItems'
import { useTranslation } from 'react-i18next'

const Promotions = () => {
    const { t } = useTranslation()
    return (
        <>
            <SubpageBanner title={t("Promosyonlar")} />
            <PromotionsItems />
        </>
    )
}

export default Promotions