import React from 'react'
import SubpageBanner from "../components/SubpageBanner"
import RegisterForm from '../components/Register/RegisterForm'
import { useTranslation } from 'react-i18next'

const Register = () => {
    const { t } = useTranslation();
    return (
        <>
            <SubpageBanner title={t("register")} />
            <RegisterForm />
        </>
    )
}

export default Register