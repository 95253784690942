import React, { useEffect } from 'react'
import Slider from "react-slick";
import PrevArrow from "../assets/icons/prev-arrow.svg"
import NextArrow from "../assets/icons/next-arrow.svg"
import Quote from "../assets/icons/quote.svg"
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

const HomepageCustomerReview = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "40px",
                    // height: "40px",
                    // background: "#AD66CF", // mor renk
                    // borderRadius: "50%",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    cursor: "pointer",
                    zIndex: 1,
                    top: "auto",
                    bottom: 0,
                    right: "41%",
                    bottom: "-35px"
                }}
                onClick={onClick}
            >
                <img src={NextArrow} alt="next-arrow" width="14px" />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // width: "40px",
                    // height: "40px",
                    // background: "#AD66CF", // mor renk
                    // borderRadius: "50%",
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    cursor: "pointer",
                    zIndex: 1,
                    top: "auto",
                    bottom: 0,
                    left: "41%",
                    bottom: "-35px"
                }}
                onClick={onClick}
            >
                <img src={PrevArrow} alt="prev-arrow" width="14px" />
            </div>
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
        ]
    };

    const data = [
        {
            name: "Mustafa Çakıl",
            title: t("reviewjob1"),
            text: t("reviewtext1")
        },
        {
            name: "Gülnaz Yiğit",
            title: t("reviewjob2"),
            text: t("reviewtext2")
        },
        {
            name: "Bora Altuner",
            title: t("reviewjob3"),
            text: t("reviewtext3")
        },
    ]

    return (
        <div className='homepage-customer-review'>
            <div className="container">
                <h5 className="homepage-section-title text-center" data-aos="fade-down">
                    {t("reviewtitle")}
                </h5>
                <div className="slider-container mt-5" data-aos="zoom-in-up">
                    <Slider {...settings}>
                        {
                            data.map((e, index) => (
                                <div key={index} className='slider-item px-lg-3 pb-5'>
                                    <div className="homepage-customer-review-card">
                                        <div className='mb-3 border-bottom pb-3 position-relative'>
                                            <h5 className='mb-1'>{e.name}</h5>
                                            <p className="mb-0" style={{ color: "#AD66CF" }}>{e.title}</p>
                                            <img src="https://tamilnaducouncil.ac.in/wp-content/uploads/2020/04/dummy-avatar.jpg" alt="" width="75px" className='rounded-pill homepage-review-image' />
                                            <img src={Quote} alt="" width="48px" className='quote' />
                                        </div>
                                        <div className='pt-4'>
                                            <p className="mb-0" style={{ fontWeight: 300 }}>
                                                {e.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default HomepageCustomerReview;
