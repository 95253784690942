import React, { useEffect } from 'react'
import Worldwide from "../assets/icons/international.svg"
import Local from "../assets/icons/local.svg"
import AOS from 'aos';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HomepageGlobal = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className='homepage-global-content'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5" data-aos="fade-right">
                        <img src="https://jan.coderdemo.com/newwp/bullion/wp-content/uploads/2023/03/apps-1.jpg" alt="" width="100%" />
                    </div>
                    <div className="col-lg-7 ps-lg-5">
                        <h5 className='homepage-section-title mb-3 mt-lg-0 mt-md-0 mt-3' data-aos="fade-left">{t("globalperspective")}</h5>
                        <p className="mb-0 homepage-section-text" data-aos="fade-left" data-aos-duration="1200">
                            {t("globalperspectivetext")}
                        </p>
                        <div className='mt-5'>
                            <div className='d-flex align-items-center' data-aos="fade-right">
                                <div className="homepage-global-icon shadow-sm">
                                    <img src={Worldwide} alt="icon" width="32px" />
                                </div>
                                <div className='ps-4'>
                                    <h6 className='mb-2' style={{ fontSize: 18 }}>{t("globalitemtitle1")}</h6>
                                    <p className="mb-0" style={{ fontSize: 15 }}>{t("globalitemtext1")}</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-4' data-aos="fade-right">
                                <div className="homepage-global-icon shadow-sm">
                                    <img src={Local} alt="icon" width="32px" />
                                </div>
                                <div className='ps-4'>
                                    <h6 className='mb-2' style={{ fontSize: 18 }}>{t("globalitemtitle2")}</h6>
                                    <p className="mb-0" style={{ fontSize: 15 }}>{t("globalitemtext2")}</p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 homepage-global-button' data-aos="fade-left">
                            <Link to="advantages">
                                <button className='btn btn-primary-v2'>{t("globalinvest")}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageGlobal