import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';

import "./i18";

import Header from './components/Header';
import Homepage from './pages/Homepage';
import Footer from './components/Footer';
import About from './pages/About';
import Investment from './pages/Investment';
import Promotions from './pages/Promotions';
import PromotionDetail from './pages/PromotionDetail';
import TradingPlatforms from './pages/TradingPlatforms';
import Advantages from './pages/Advantages';
import Contact from './pages/Contact';
import Register from './pages/Register';
import PrivacyPolicy from './pages/PrivacyPolicy';

import ScrollToTop from "./components/ScrollToTop"
import CookiePolicy from './pages/CookiePolicy';
import Kvkk from './pages/Kvkk';

function App() {
    return (
        <>
            <Router>
                <Header />
                <ScrollToTop />
                <Routes>
                    <Route path='/' element={<Homepage />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/investment' element={<Investment />} />
                    <Route path='/promotions' element={<Promotions />} />
                    <Route path='/promotion/:title' element={<PromotionDetail />} />
                    <Route path='/trading-platforms' element={<TradingPlatforms />} />
                    <Route path='/advantages' element={<Advantages />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/cookie-policy' element={<CookiePolicy />} />
                    <Route path='/kvkk' element={<Kvkk />} />
                </Routes>
                <Footer />
            </Router>
        </>
    );
}

export default App;
