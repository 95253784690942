import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCreative, Autoplay } from 'swiper/modules';
import AOS from 'aos';
import Banner1 from "../assets/img/banner-1.png"
import Banner2 from "../assets/img/banner-2.png"
import Banner3 from "../assets/img/banner-3.png"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className='banner-content' data-aos="zoom-in">
            <Swiper
                effect={'creative'}
                loop={true}
                creativeEffect={{
                    prev: {
                        shadow: true,
                        translate: [0, 0, -400],
                    },
                    next: {
                        translate: ['100%', 0, 0],
                    },
                }}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    dynamicBullets: true,
                }}
                modules={[Autoplay, Pagination, EffectCreative]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="banner-background-image" style={{ backgroundImage: `url(${Banner1})` }}>
                        <div className="container">
                            <div className="banner-text-container">
                                <h1 className='mb-3'>
                                    Nova Invest
                                </h1>
                                <h3>
                                    {t("Geleceğinizi Şekillendirecek Çözümler, Güçlü Bir Yatırımın Anahtarı")}
                                </h3>
                                <Link to="/register">
                                    <button className="btn btn-light px-lg-5 px-4 py-lg-3 py-2 mt-4">{t("Hemen Hesap Oluştur")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="banner-background-image" style={{ backgroundImage: `url(${Banner2})` }}>
                        <div className="container">
                            <div className="banner-text-container">
                                <h1 className='mb-3'>
                                    {t("Fırsat Zamanı")}
                                </h1>
                                <h3>
                                    {t("Kaçırılmayacak fırsatlarla yatırımınızı büyütün! Nova Invest’in özel promosyonlarıyla kazancınızı maksimize edin.")}
                                </h3>
                                <Link to="/promotions">
                                    <button className="btn btn-light px-lg-5 px-4 py-lg-3 py-2 mt-4">{t("Daha Fazlasını Keşfet")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <div className="banner-background-image" style={{ backgroundImage: `url(${Banner3})` }}></div>
                </SwiperSlide> */}
            </Swiper>
        </div>
    )
}

export default Banner