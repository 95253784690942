import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const url =
    window.location.host === "localhost:3000"
        ? "localhost:3000"
        : window.location.host;

const storedLanguage = JSON.parse(localStorage.getItem("lang"));

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: "tr",
        lng: storedLanguage || "tr",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: window.location.hostname.includes("localhost")
                ? `http://localhost:3000/i18n/{{lng}}.json`
                : `https://${url}/i18n/{{lng}}.json`,
        },
    });

export default i18n;
