import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ContactForm = () => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formUrl, setFormUrl] = useState();

    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 991px)").matches
    )

    useEffect(() => {
        setFormUrl(`${'https://' + (window.location.hostname.replace('www.', '')) + "/api/v1/Submit?brand=2"} `)
        //setFormUrl(`${'https://' + "nova.testmedici.com" + "/api/v1/Submit?brand=2"} `)
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            phone: '',
            message: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('İsim gerekli')),
            surname: Yup.string().required(t('Soyisim gerekli')),
            email: Yup.string().email(t('Geçersiz e-posta')).required(t('E-posta gerekli')),
            phone: Yup.string()
                .required(t('Telefon numarası gerekli')),
            message: Yup.string().required(t('Mesajınız gerekli')),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true)
            try {
                const data = {
                    firstName: values.name,
                    lastName: values.surname,
                    email: values.email,
                    phone: String(values.phone).replace("(", "").replace(")", "").replaceAll(" ", ""),
                    message: values.message
                };

                const response = await axios.post(formUrl, data);
                toast.success(t('Mesajınız başarıyla iletilmiştir.'));

                setIsSubmitting(false)
            } catch (error) {
                const errorMessage = error.response.data.message;

                if (errorMessage.includes("Phone Number or Email Address")) {
                    toast.error(t('Bu e-posta adresi ya da telefon numarası zaten kullanılıyor. Lütfen bilgileri kontrol edin.'));
                }
                else if (errorMessage.includes("Email already in use !!")) {
                    toast.error(t("Bu e-posta adresi zaten kullanılıyor."))
                }
                else if (errorMessage.includes("Phone Number is already in use ..")) {
                    toast.error(t("Bu telefon numarası zaten kullanılıyor."))
                }
                else if (errorMessage.includes("Unexpected character encountered")) {
                    toast.error(t("Parolanız geçersiz. Lütfen parolanızda en az bir harf ve bir rakam kullanın."))
                }
                else {
                    toast.error(t("Beklenmedik bir hata oluştu. Lütfen tekrar deneyin."))
                }
                setIsSubmitting(false)
            }
        }
    });

    useEffect(() => {
        window
            .matchMedia("(max-width: 991px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    return (
        <div className='contact-form-content'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact-form-card shadow-sm border rounded h-100">
                            <img width="46" height="46" src="https://img.icons8.com/ios/46/address.png" alt="address" />
                            <h5 className='mt-3'>{t("Adres")}</h5>
                            <Link to="https://maps.app.goo.gl/WYXn8TAjEAoeFpv88" target='_blank' className='text-muted text-decoration-none'>
                                {t("22 Bishopsgate, Londra EC2N 4AJ Birleşik Krallık")}
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-lg-0 mt-4">
                        <div className="contact-form-card shadow-sm border rounded h-100">
                            <img width="46" height="46" src="https://img.icons8.com/ios/46/contact-card.png" alt="contact-card" />
                            <h5 className='mt-3'>{t("İletişim")}</h5>
                            <div>
                                <a href="mailto:support@nova-markets.com" className='mt-3 mb-1 text-decoration-none text-muted'>support@nova-markets.com</a>
                            </div>
                            <div className='my-2'>
                                <img width="24" height="24" src="https://img.icons8.com/ios-glyphs/24/25D366/whatsapp.png" alt="whatsapp" />
                                <a href="https://wa.me/447597058146" target='_blank' className='text-decoration-none text-muted'>+44 75970 58146</a>
                            </div>
                            <div>
                                <a href="tel:+447597058146" className='text-decoration-none text-muted'>+44 75970 58146</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-lg-0 mt-4">
                        <div className="contact-form-card shadow-sm border rounded h-100">
                            <img width="46" height="46" src="https://img.icons8.com/ios/46/add-user-male.png" alt="add-user-male" />
                            <h5 className='mt-3'>{t("Bizi Takip Edin")}</h5>
                            <div className="d-flex align-items-center justify-content-center mt-3">
                                <div className="social-media-icon">
                                    <a href="https://www.facebook.com/novainvestofc" target='_blank'>
                                        <img width="24" height="24" src="https://img.icons8.com/ios-glyphs/50/ffffff/facebook-f.png" alt="facebook-f" />
                                    </a>
                                </div>
                                <div className="social-media-icon">
                                    <a href="https://x.com/novainvestofc" target='_blank'>
                                        <img width="24" height="24" src="https://img.icons8.com/ios/50/ffffff/twitterx--v2.png" alt="twitterx--v2" />
                                    </a>
                                </div>
                                <div className="social-media-icon">
                                    <a href="https://www.instagram.com/novainvestofc/" target='_blank'>
                                        <img width="24" height="24" src="https://img.icons8.com/ios/50/ffffff/instagram-new--v1.png" alt="instagram-new--v1" />
                                    </a>
                                </div>
                                <div className="social-media-icon">
                                    <a href="https://www.threads.net/@novainvestofc" target='_blank'>
                                        <img width="24" height="24" src="https://img.icons8.com/pulsar-line/50/ffffff/threads.png" alt="threads" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <h3 className='h2'>{t("Bizimle İletişime Geçin")}</h3>
                    <p>{t("Aşağıdaki formu doldurarak bizimle iletişime geçebilirsiniz")}</p>
                    <form className='mt-5' onSubmit={formik.handleSubmit}>
                        <div className='row'>
                            <div className="col-lg-6">
                                <div className='row'>
                                    <div className="col-lg-6">
                                        <div>
                                            <label htmlFor="name">{t("İsminiz")}</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                id='name'
                                                placeholder={t('İsminizi buraya girin')}
                                                {...formik.getFieldProps('name')}
                                            />
                                            {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-0 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="surname">{t("Soyisminiz")}</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                id='surname'
                                                placeholder={t('Soyisminizi buraya girin')}
                                                {...formik.getFieldProps('surname')}
                                            />
                                            {formik.touched.surname && formik.errors.surname ? <div className="text-danger">{formik.errors.surname}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="email">{t("E-posta Adresiniz")}</label>
                                            <input
                                                type="email"
                                                className='form-control'
                                                id='email'
                                                placeholder={t('E-posta adresinizi buraya girin')}
                                                {...formik.getFieldProps('email')}
                                            />
                                            {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="phone">{t("Telefon Numaranız")}</label>
                                            <MaskedInput
                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                                className='form-control'
                                                id='phone'
                                                placeholder={t('Telefon numaranızı buraya girin')}
                                                {...formik.getFieldProps('phone')}
                                            />
                                            {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null}
                                        </div>
                                    </div>
                                    {
                                        matches &&
                                        <div className="col-lg-6 mt-lg-0 mt-md-0 mt-3">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="phone">{t("Mesajınız")}</label>
                                                <textarea className='message form-control' placeholder={t('Mesajınızı buraya girin')} {...formik.getFieldProps('message')}></textarea>
                                                {formik.touched.message && formik.errors.message ? <div className="text-danger">{formik.errors.message}</div> : null}
                                            </div>
                                        </div>
                                    }
                                    <div className="col-lg-12 mt-4">
                                        <button className='btn btn-primary-v2 w-100 py-2' disabled={isSubmitting}>
                                            {isSubmitting ? t("sending") : t("Mesaj Gönder")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {
                                !matches &&
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="phone">{t("Mesajınız")}</label>
                                                <textarea className='message form-control' placeholder={t('Mesajınızı buraya girin')} {...formik.getFieldProps('message')}></textarea>
                                                {formik.touched.message && formik.errors.message ? <div className="text-danger">{formik.errors.message}</div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <ToastContainer />
                    </form>
                </div>
                <div style={{ marginTop: 75 }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2482.9344324114118!2d-0.0856228!3d51.5144189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760352ae24d451%3A0x218c8a2cec4e6e8f!2s22%20Bishopsgate%2C%20London%20EC2N%204BQ%2C%20Birle%C5%9Fik%20Krall%C4%B1k!5e0!3m2!1str!2sbg!4v1729607469097!5m2!1str!2sbg" width="100%" height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </div>
            </div>
        </div>
    )
}

export default ContactForm