import React, { useEffect } from 'react'
import AOS from 'aos';
import Icon1 from "../../assets/icons/customer-disi.svg"
import Icon2 from "../../assets/icons/reliability-disi.svg"
import Icon3 from "../../assets/icons/techology-disi.svg"
import Icon4 from "../../assets/icons/custom-disi.svg"
import Performance from "../../assets/icons/performance.svg"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AboutItems = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    const data = [

        {
            title: t("aboutpageitemtitle1"),
            text: t("aboutpageitemtext1"),
            icon: Icon1,
            dataos: 1000
        },
        {
            title: t("aboutpageitemtitle2"),
            text: t("aboutpageitemtext2"),
            icon: Icon2,
            dataos: 1500
        },
        {
            title: t("aboutpageitemtitle3"),
            text: t("aboutpageitemtext3"),
            icon: Icon3,
            dataos: 2000
        },
        {
            title: t("aboutpageitemtitle4"),
            text: t("aboutpageitemtext4"),
            icon: Icon4,
            dataos: 2500
        },
    ]
    return (
        <div className='about-items-content'>
            <div className="container">
                <h5 style={{ fontWeight: 400, fontSize: "18px" }} data-aos="fade-right">{t("aboutpagetext")}</h5>
                <div className="row align-items-center about-items-container">
                    <div className="col-lg-6">
                        <div className="row">
                            {
                                data.map((e) => (
                                    <div className="col-lg-6" data-aos="zoom-in-up" data-aos-duration={e.dataos}>
                                        <div className="about-item-card position-relative">
                                            <div className="about-item-card-icon">
                                                <img width="30" height="30" src={e.icon} alt="electronics" />
                                            </div>
                                            <h5 style={{ fontSize: 19 }}>{e.title}</h5>
                                            <p className="mb-0" style={{ fontSize: 15 }}>
                                                {e.text}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-lg-6 ps-lg-5 mt-lg-0 mt-md-0 mt-5">
                        <h3 className='mb-3 about-items-title' data-aos="fade-left" data-aos-duration="1000">
                            Nova Invest<br />{t("aboutpagetitle")}
                        </h3>
                        <p className="mb-0" data-aos="fade-left" data-aos-duration="1500">
                            {t("aboutpagecontenttext")}
                        </p>
                        <div className='mt-5'>
                            <div className='d-flex align-items-start' data-aos="fade-left" data-aos-duration="1500">
                                <img src={Performance} alt="icon" width="64px" />
                                <div className='ms-3'>
                                    <h5>{t("aboutpagecontentitemtitle")}</h5>
                                    <p className="mb-0">
                                        {t("aboutpagecontentitemtext")}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 about-item-btn" data-aos="fade-left" data-aos-duration="1500">
                                <Link to="/contact">
                                    <button className='btn btn-primary-v2 px-5'>{t("getcontact")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutItems