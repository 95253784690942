import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import { useTranslation } from 'react-i18next'

const CookiePolicy = () => {
    const { t } = useTranslation()
    return (
        <>
            <SubpageBanner title={t("Çerez Politikası")} />
            <div className="container mt-5">
                <h3>{t("Çerez Politikası")}</h3>
                <p className='mt-4'>{t("Nova Invest olarak, web sitemizi ziyaret ettiğinizde kullanıcı deneyiminizi iyileştirmek, site performansını artırmak ve size daha kişiselleştirilmiş hizmetler sunmak amacıyla çerezler kullanıyoruz. Çerezler, tarayıcınıza yerleştirilen ve cihazınıza kaydedilen küçük veri dosyalarıdır. Bu dosyalar, web sitemizi nasıl kullandığınızı anlamamıza, tercihlerinizi hatırlamamıza ve site performansını analiz etmemize yardımcı olur.")}</p>
                <p>{t("Çerezler sayesinde, web sitemizdeki gezinme davranışlarınızı izleyebilir, ilgi alanlarınıza göre içerikler sunabilir ve ziyaretlerinizi optimize edebiliriz. Çerezler ayrıca, hizmetlerimizi nasıl geliştirebileceğimizi anlamamıza olanak sağlar.")}</p>
                <p>{t("Tarayıcınızın ayarlarından çerezleri kabul etmeyi veya reddetmeyi seçebilirsiniz. Ancak, çerezleri devre dışı bırakmanız durumunda, web sitemizin bazı özelliklerinden tam anlamıyla yararlanamayabilirsiniz. Çerezlerin nasıl yönetileceği konusunda daha fazla bilgi almak için tarayıcınızın yardım bölümüne başvurabilirsiniz.")}</p>
                <p>{t("Çerez politikamızla ilgili sorularınız varsa, bize support@nova-markets.com üzerinden ulaşabilirsiniz.")}</p>
            </div>
        </>
    )
}

export default CookiePolicy