import React, { useEffect } from 'react'
import Customer from "../assets/icons/customer.svg"
import PrevArrow from "../assets/icons/prev-arrow-disi.svg"
import NextArrow from "../assets/icons/next-arrow-disi.svg"
import Slider from "react-slick";
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

const HomepageWhyNova = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    zIndex: 1,
                    top: "auto",
                    bottom: 0,
                    right: "0",
                    top: "-65px"
                }}
                onClick={onClick}
            >
                <img src={NextArrow} alt="next-arrow" width="14px" />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    zIndex: 1,
                    top: "auto",
                    bottom: 0,
                    left: "85%",
                    top: "-65px"
                }}
                onClick={onClick}
            >
                <img src={PrevArrow} alt="prev-arrow" width="14px" />
            </div>
        );
    }

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
        ]
    };

    const data = [
        {
            icon: Customer,
            title: t("Geniş Yatırım Olanakları"),
            text: t("Nova Invest, müşterilerine forex, emtia, hisse senetleri ve kripto paralar gibi çeşitli finansal enstrümanlarda geniş bir yatırım yelpazesi sunar. Bu çeşitlilik, yatırımcılara farklı piyasa koşullarına adapte olma ve portföylerini çeşitlendirme imkanı sağlar.")
        },
        {
            icon: Customer,
            title: t("Teknik Analiz Eğitimleri"),
            text: t("Yatırımcıların piyasa trendlerini daha iyi anlamalarını ve etkili yatırım stratejileri oluşturmalarını desteklemek adına Nova Invest, teknik analiz eğitimleri sunar. Bu eğitimlerle müşteriler, grafikleri okuma ve piyasa hareketlerini daha iyi yorumlama becerilerini geliştirebilirler.")
        },
        {
            icon: Customer,
            title: t("Global Perspektif"),
            text: t("Londra merkezli olan Nova Invest, global finans piyasalarındaki fırsatları değerlendirme konusunda müşterilerine avantaj sunar. Uzman analistlerimiz, dünya genelindeki ekonomik gelişmeleri yakından takip eder ve müşterilere uluslararası piyasalardaki potansiyel getirilere erişim sağlar.")
        },
        {
            icon: Customer,
            title: t("Müşteri Odaklı Hizmet Anlayışı"),
            text: t("Nova Invest, müşteri memnuniyetini en üst düzeyde tutan bir anlayışa sahiptir. Her müşterinin ihtiyaçlarına özel çözümler sunarak, finansal hedeflerine ulaşmalarına destek olmayı amaçlar. Güvenilirlik, şeffaflık ve sürekli gelişim ilkeleriyle müşterilere güven verir ve onların finansal başarılarına ortak olur.")
        },

    ]

    return (
        <div className='homepage-why-nova-content'>
            <div className="container" data-aos="zoom-in">
                <h5 className="homepage-section-title text-light">{t("Neden Nova Invest?")}</h5>
                <div className='mt-5'>
                    <Slider {...settings}>
                        {
                            data.map((e) => (
                                <div className='px-3 h-100'>
                                    <div className="homepage-why-nova-card text-center h-100">
                                        <img src={e.icon} alt="customer-icon" width="42px" className='mb-4 mx-auto' />
                                        <h5 className='mb-3'>{e.title}</h5>
                                        <p className="mb-0">{e.text}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default HomepageWhyNova