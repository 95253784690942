import React, { useState, useEffect } from 'react'
import Account from "../assets/icons/account.svg"
import Email from "../assets/icons/email.svg"
import Hamburger from "../assets/icons/hamburger.svg"
import Logo from "../assets/img/logo.svg"
import { LANGUAGES } from "../constants";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Header = () => {
    const [isActive, setIsActive] = useState(false);
    const [scaUrl, setScaUrl] = useState();
    const [currentLink, setCurrentLink] = useState(window.location.pathname);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 300) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

    const { i18n, t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(
        JSON.parse(localStorage.getItem("lang"))
            ? JSON.parse(localStorage.getItem("lang"))
            : "tr"
    );
    const onChangeLang = (e) => {
        const lang_code = e.code;
        i18n.changeLanguage(lang_code);
        setSelectedLanguage(e.code);
    };
    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, []);

    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, [selectedLanguage]);

    const [currentLang, setCurrentLang] = useState(
        LANGUAGES.find((lang) => lang.code === selectedLanguage) || LANGUAGES.find((lang) => lang.code === "tr")
    );

    const toggleLanguage = () => {
        const newLang = currentLang.code === "tr" ? LANGUAGES.find((lang) => lang.code === "en") : LANGUAGES.find((lang) => lang.code === "tr");
        setCurrentLang(newLang);
        onChangeLang(newLang); // Dil değiştirme fonksiyonunu tetikler
    };

    useEffect(() => {
        let scaurl = "sca." + window.location.host.replace("www.", "");
        setScaUrl(scaurl)
    }, [])

    return (
        <>
            <div className={`header-content ${isActive ? "header-content-fixed" : ""}`}>
                <div className="header-top py-2" data-aos="fade-down" data-aos-duration="1500">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3">
                                <div className="border-end">
                                    <div className="d-flex align-items-center">
                                        <div className='me-3'>
                                            <img src={Account} alt="account" width="38px" />
                                        </div>
                                        <div className='header-top-account d-flex flex-column'>
                                            <div>
                                                <Link to={`https://${scaUrl}`} target='_blank' className='mb-0 text-decoration-none me-2' style={{ fontSize: 15 }}>
                                                    {t("login")}
                                                </Link>
                                                <span style={{ fontWeight: 400, color: "#ababab", fontSize: 13 }}>-{t("or")}</span>
                                            </div>
                                            <Link to="/register" className='mb-0 text-decoration-none text-dark' style={{ fontWeight: 500, fontSize: 13 }}>{t("register")}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                {/* <div className="text-center">
                                    <h5 className='header-top-announcement mb-0'>Traders payouts since 2015: $26,769,485</h5>
                                </div> */}
                            </div>
                            <div className="col-lg-3">
                                <div className="border-start">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className='ps-2'>
                                            <img width="24" height="24" src="https://img.icons8.com/ios-glyphs/24/25D366/whatsapp.png" alt="whatsapp" />
                                            <a href="https://wa.me/447597058146" target='_blank' className='text-decoration-none text-muted' style={{ fontSize: 13, fontWeight: 500 }}>+44 75970 58146</a>
                                        </div>
                                        <button className="language-toggle-btn" onClick={toggleLanguage}>
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={currentLang.flag}
                                                    alt={currentLang.label}
                                                    width="28px"
                                                    className="me-2"
                                                />
                                                {currentLang.label}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header border-top py-2" data-aos="fade-down">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-2">
                                <Link to="/">
                                    <img src={Logo} alt="logo" width="125px" />
                                </Link>
                            </div>
                            <div className="col-lg-10">
                                <ul className='navbar-menu'>
                                    <li>
                                        <NavLink to="/">
                                            {t("homepage")}
                                        </NavLink>
                                    </li>
                                    <li><NavLink to="/about">{t("about")}</NavLink></li>
                                    <li><NavLink to="/investment">{t("investment")}</NavLink></li>
                                    <li><NavLink to="/promotions">{t("promotions")}</NavLink></li>
                                    <li><NavLink to="/trading-platforms">{t("tradingplatforms")}</NavLink></li>
                                    <li><NavLink to="/advantages">{t("advantages")}</NavLink></li>
                                    <li><NavLink to="/contact">{t("contact")}</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-header">
                <div className='d-flex align-items-center justify-content-between'>
                    <Link to="/">
                        <img src={Logo} alt="logo" width="125px" />
                    </Link>
                    <div className='d-flex align-items-center'>
                        <div className='me-3'>
                            <a href="https://wa.me/447597058146" target='_blank'>
                                <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/25D366/whatsapp.png" alt="whatsapp" />
                            </a>
                        </div>
                        <button className="language-toggle-btn" onClick={toggleLanguage}>
                            <div className="d-flex align-items-center">
                                <img
                                    src={currentLang.flag}
                                    alt={currentLang.label}
                                    width="34px"
                                    className="me-2"
                                />
                            </div>
                        </button>
                        <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <img src={Hamburger} alt="" />
                        </button>
                    </div>
                </div>
                <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div className="offcanvas-header">
                        <img src={Logo} alt="logo" width="120px" />
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mt-3">
                        <ul className="mobile-navigation">
                            <li><NavLink to="/">{t("homepage")}</NavLink></li>
                            <li><NavLink to="/about">{t("about")}</NavLink></li>
                            <li><NavLink to="/investment">{t("investment")}</NavLink></li>
                            <li><NavLink to="/promotions">{t("promotions")}</NavLink></li>
                            <li><NavLink to="/trading-platforms">{t("tradingplatforms")}</NavLink></li>
                            <li><NavLink to="/advantages">{t("advantages")}</NavLink></li>
                            <li><NavLink to="/contact">{t("contact")}</NavLink></li>
                        </ul>
                        <div className='d-flex flex-column mt-5'>
                            <Link to={`https://${scaUrl}`} target='_blank'>
                                <button className='btn btn-light border shadow-sm py-2 w-100'>{t("login")}</button>
                            </Link>
                            <Link to="/register">
                                <button className='btn btn-primary-v2 mt-3 w-100'>{t("register")}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header