import React, { useState, useEffect } from 'react'

const PriceFlow = () => {
    const [resultData, setResultData] = useState([]);

    const getData = async () => {
        try {
            const response = await fetch('https://nova-markets.com/api/v1/Price/GetPrices?symbols=EUR%2FUSD%2CUSD%2FJPY%2CGBP%2FUSD%2CAUD%2FUSD%2CUSD%2FCAD%2CUSD%2FCHF%2CNZD%2FUSD%2CEUR%2FJPY%2CGBP%2FJPY%2CEUR%2FGBP')
            if (response.ok) {
                const data = await response.json();
                setResultData(data)
                console.log(resultData)
            } else {
                console.log("Network response was not ok.")
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <iframe src="https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,20,39,13,4&border=show&speed=50&click_target=blank&theme=transparent&tm-cr=212529&hr-cr=FFFFFF13&by-cr=28A745&sl-cr=DC3545&flags=circle&d_mode=compact-name&column=ask,bid,spread,chg_per&lang=en&font=Arial, sans-serif" width="100%" height={85} style={{ border: 'unset' }} /><style type="text/css" dangerouslySetInnerHTML={{ __html: "#fx-pricing-widget-copyright{text-align: center; font-size: 13px; font-family: sans-serif; margin-top: 10px; margin-bottom: 10px; color: #9DB2BD;} #fx-pricing-widget-copyright a{text-decoration: unset; color: #BB3534; font-weight: 600;}" }} />
        </>
    )
}

export default PriceFlow;