import React, { useEffect } from 'react'
import Invest1 from "../../assets/img/gold-investment.jpg"
import Invest2 from "../../assets/img/stock.jpg"
import Invest3 from "../../assets/img/exchange.jpg"
import Invest4 from "../../assets/img/crypto.jpg"
import AOS from 'aos';
import { useTranslation } from 'react-i18next'

const InvestmentItems = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <>
            <div className="investment-items-content">
                <div className="container">
                    <div className="d-flex align-items-start investment-mobile">
                        <div className="nav flex-column nav-pills" data-aos="fade-right" style={{ width: "55%", zIndex: 1 }} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">{t("investmentitemtitle1")}</button>
                            <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">{t("investmentitemtitle2")}</button>
                            <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">{t("investmentitemtitle3")}</button>
                            <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">{t("investmentitemtitle4")}</button>
                        </div>
                        <div className="tab-content" data-aos="fade-left" id="v-pills-tabContent" style={{ width: "100%" }}>
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex={0}>
                                <div>
                                    <h3 className='mb-1'>{t("investmentitem1title1")}</h3>
                                    <p>{t("investmentitem1text1")}</p>
                                </div>
                                <div className='mt-4'>
                                    <h3 className='mb-1'>{t("investmentitem1title2")}</h3>
                                    <p>{t("investmentitem1text2")}</p>
                                </div>
                                <div className='investment-item-image mt-5' style={{ backgroundImage: `url(${Invest1})` }}></div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex={0}>
                                <div>
                                    <h3 className='mb-1'>{t("investmentitem2title1")}</h3>
                                    <p>{t("investmentitem2text1")}</p>
                                </div>
                                <div className='mt-4'>
                                    <h3 className='mb-1'>{t("investmentitem2title2")}</h3>
                                    <p>{t("investmentitem2text2")}</p>
                                </div>
                                <div className='investment-item-image mt-5' style={{ backgroundImage: `url(${Invest2})` }}></div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex={0}>
                                <div>
                                    <h3 className='mb-1'>{t("investmentitem3title1")}</h3>
                                    <p>{t("investmentitem3text1")}</p>
                                </div>
                                <div className='mt-4'>
                                    <h3 className='mb-1'>{t("investmentitem3title2")}</h3>
                                    <p>{t("investmentitem3text2")}</p>
                                </div>
                                <div className='investment-item-image mt-5' style={{ backgroundImage: `url(${Invest3})`, backgroundPosition: "bottom" }}></div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex={0}>
                                <div>
                                    <h3 className='mb-1'>{t("investmentitem4title1")}</h3>
                                    <p>{t("investmentitem4text1")}</p>
                                </div>
                                <div className='mt-4'>
                                    <h3 className='mb-1'>{t("investmentitem4title2")}</h3>
                                    <p>{t("investmentitem4text2")}</p>
                                </div>
                                <div className='investment-item-image mt-5' style={{ backgroundImage: `url(${Invest4})`, backgroundPosition: "bottom" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvestmentItems