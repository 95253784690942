import React from 'react'
import SubpageBanner from "../components/SubpageBanner"
import TradingPlatform from '../components/TradingPlatforms/TradingPlatform';
import CTA from '../components/CTA';
import { useTranslation } from 'react-i18next';

const TradingPlatforms = () => {
    const { t } = useTranslation();
    return (
        <>
            <SubpageBanner title={t("İşlem Platformları")} />
            <TradingPlatform />
            <CTA />
        </>
    )
}

export default TradingPlatforms;