import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import AdvantagesItems from '../components/Advantages/AdvantagesItems'
import { useTranslation } from 'react-i18next'

const Advantages = () => {
    const { t } = useTranslation();
    return (
        <>
            <SubpageBanner title={t("Nova Invest Avantajları")} />
            <AdvantagesItems />
        </>
    )
}

export default Advantages