import React, { useEffect } from 'react'
import AOS from 'aos';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CTA = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className='cta-content'>
            <div className="container cta-content-container position-relative" data-aos="zoom-in-up">
                <div className="row align-items-baseline">
                    <div className="col-lg-5">
                        <h5 className='homepage-section-title mb-3'>
                            {t("ctatitle")}
                        </h5>
                        <p className="mb-0">
                            {t("ctatext")}
                        </p>
                        <Link to="/register">
                            <button className='btn btn-light px-lg-5 py-lg-3 px-4 py-2 mt-4'>{t("ctabutton")}</button>
                        </Link>
                    </div>
                    <img src="https://sept.hostlin.com/newwp/fortradex/wp-content/uploads/2024/07/mockup-1.png" alt="cta-image" className='cta-image' data-aos="zoom-in-up" data-aos-duration="2500" />
                </div>
            </div>
        </div>
    )
}

export default CTA;