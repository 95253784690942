import React, { useState, useEffect } from 'react'
import Logo from "../assets/img/logo.svg"
import Apple from "../assets/icons/apple.png"
import Android from "../assets/icons/android.svg"
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    const [app, setApp] = useState({
        ios: "",
        android: ""
    })

    useEffect(() => {
        getApps();
    }, [])

    const getApps = () => {
        const location = window.location.origin
        let url;
        if (location.includes("localhost") || location.includes("test")) {
            url = "https://nova.testmedici.com/api/Global/Apps"
        } else {
            url = `${location}/api/Global/Apps`
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setApp({
                    ios: data.ios,
                    android: data.android
                })
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }
    
    return (
        <div className='footer-content'>
            <div className="container">
                <div className="row" style={{ paddingBottom: 25 }}>
                    <div className="col-lg-3">
                        <h5 className='mb-3'>{t("Faydalı Linkler")}</h5>
                        <ul>
                            <li><NavLink to="/">{t("Ana Sayfa")}</NavLink></li>
                            <li><NavLink to="/about">{t("Hakkımızda")}</NavLink></li>
                            <li><NavLink to="/investment">{t("Yatırım")}</NavLink></li>
                            <li><NavLink to="/promotions">{t("Promosyonlar")}</NavLink></li>
                            <li><NavLink to="/trading-platforms">{t("İşlem Platformları")}</NavLink></li>
                            <li><NavLink to="/advantages">{t("Nova Invest Avantajları")}</NavLink></li>
                            <li><NavLink to="/contact">{t("İletişim")}</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 mt-lg-0 mt-3">
                        <h5 className='mb-3'>{t("Yasal")}</h5>
                        <ul>
                            <li><NavLink to="/privacy-policy">{t("Gizlilik Politikası")}</NavLink></li>
                            <li><NavLink to="/cookie-policy">{t("Çerez Politikası")}</NavLink></li>
                            <li><NavLink to="/kvkk">{t("Kişisel Verilerin Korunması")}</NavLink></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 mt-lg-0 mt-3">
                        <h5 className='mb-3'>{t("İletişim Bilgileri")}</h5>
                        <ul>
                            <li className='mb-3'>
                                <p style={{ fontWeight: 500, fontSize: 14 }} className='text-dark mb-0'>{t("Adres")}</p>
                                <Link to="https://maps.app.goo.gl/WYXn8TAjEAoeFpv88" target='_blank'>
                                    {t("22 Bishopsgate, Londra EC2N 4AJ Birleşik Krallık")}
                                </Link>
                            </li>
                            <li className='mb-3'>
                                <p style={{ fontWeight: 500, fontSize: 14 }} className='text-dark mb-0'>{t("E-posta Adresi")}</p>
                                <a href="mailto:support@nova-markets.com">support@nova-markets.com</a>
                            </li>
                            <li>
                                <p style={{ fontWeight: 500, fontSize: 14 }} className='text-dark mb-0'>{t("Telefon Numarası")}</p>
                                <a href="tel:+447597058146">
                                    +44 75970 58146
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 mt-lg-0 mt-3">
                        <div className="footer-card">
                            <img src={Logo} alt="logo" width="120px" className='mb-2' />
                            <p className="mb-0" style={{ fontSize: "14px" }}>Trade multipliers on our app.</p>
                            <div className="footer-apps d-flex align-items-center justify-content-center mt-4">
                                <div className="footer-apps-item shadow">
                                    <a href={app.ios} target='_blank'>
                                        <img src={Apple} alt="apple" width="25px" />
                                    </a>
                                </div>
                                <div className="footer-apps-item shadow">
                                    <a href={app.android} target='_blank'>
                                        <img src={Android} alt="android" width="25px" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    {t("© 2024 Nova Invest. Tüm Hakları Saklıdır.")}
                </div>
            </div>
        </div>
    )
}

export default Footer