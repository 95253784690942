import React, { useEffect } from 'react'
import AOS from 'aos';
import Mission from "../../assets/img/mission.png"
import Vision from "../../assets/img/vision.png"
import { useTranslation } from 'react-i18next';

const AboutMissionVision = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <>
            <div className="about-mission-vision-content" data-aos="zoom-out">
                <div className="container">
                    <div>
                        <ul className="nav nav-pills justify-content-center" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                    {t("ourmission")}
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                    {t("ourvision")}
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content p-lg-5 p-4" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                <div className="row">
                                    <div className="col-lg-7 pe-lg-5 pe-3">
                                        <h3 className='mb-4' style={{ fontSize: 32 }}>{t("ourmission")}</h3>
                                        <p className="mb-3">
                                            {t("missiontext1")}
                                        </p>
                                        <p className="mb-3">
                                            {t("missiontext2")}
                                        </p>
                                        <p className="mb-0">
                                            {t("missiontext3")}
                                        </p>
                                    </div>
                                    <div className="col-lg-5 mt-lg-0 mt-md-0 mt-4">
                                        <img src={Mission} alt="mission" width="100%" className='rounded' />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <img src={Vision} alt="mission" width="100%" className='rounded' />
                                    </div>
                                    <div className="col-lg-7 ps-lg-5 ps-3 mt-lg-0 mt-md-0 mt-4">
                                        <h3 style={{ fontSize: 32 }}>{t("ourvision")}</h3>
                                        <p className="mb-0">
                                            {t("visiontext")}
                                        </p>
                                        <div className='mt-5'>
                                            <div>
                                                <h5 style={{ fontSize: 18 }}>
                                                    {t("visionitem1title")}
                                                </h5>
                                                <p className="mb-0" style={{ fontSize: 15 }}>
                                                    {t("visionitem1text")}
                                                </p>
                                            </div>
                                            <div className='mt-4'>
                                                <h5 style={{ fontSize: 18 }}>
                                                    {t("visionitem2title")}
                                                </h5>
                                                <p className="mb-0" style={{ fontSize: 15 }}>
                                                    {t("visionitem2text")}
                                                </p>
                                            </div>
                                            <div className='mt-4'>
                                                <h5 style={{ fontSize: 18 }}>
                                                    {t("visionitem3title")}
                                                </h5>
                                                <p className="mb-0" style={{ fontSize: 15 }}>
                                                    {t("visionitem3text")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutMissionVision