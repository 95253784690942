import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask'
import { ToastContainer, toast } from 'react-toastify';
import Android from "../../assets/icons/android-original.svg"
import Ios from "../../assets/icons/apple.png"
import WebT from "../../assets/icons/webt.svg"

const RegisterForm = () => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [scaUrl, setScaUrl] = useState();
    const [app, setApp] = useState({
        ios: "",
        android: ""
    })
    const [formUrl, setFormUrl] = useState();
    useEffect(() => {
        if (window.location.origin.includes("localhost")) {
            setFormUrl(
                "https://nova.testmedici.com/api/registration/client/Registration/RegisterUser"
            );
        } else if (window.location.origin.includes("test")) {
            setFormUrl(
                "https://nova.testmedici.com/api/registration/client/Registration/RegisterUser"
            );
        } else {
            //canlı domain
            setFormUrl(
                window.location.origin.replace("pro", "nova") +
                "/api/registration/client/Registration/RegisterUser"
            );
        }
    }, [])
    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            email: '',
            phone: '',
            password: '',
            repassword: '',
            birthDate: '',
            identity: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('İsim gerekli')),
            surname: Yup.string().required(t('Soyisim gerekli')),
            email: Yup.string().email(t('Geçersiz e-posta')).required(t('E-posta gerekli')),
            phone: Yup.string()
                .required(t('Telefon numarası gerekli')),
            password: Yup.string()
                .min(6, t('Parola en az 6 karakter olmalı'))
                .required(t('Parola gerekli')),
            repassword: Yup.string()
                .oneOf([Yup.ref('password'), null], t('Parolalar eşleşmiyor'))
                .required(t('Parola onayı gerekli')),
            birthDate: Yup.date().required(t('Doğum tarihi gerekli')),
            // identity: Yup.string()
            //     .length(11, t('TC Kimlik numarası 11 karakter olmalı'))
            //     .matches(/^[0-9]+$/, t('Sadece sayılar girin'))
            //     .required(t('TC Kimlik numarası gerekli')),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true)

            try {
                const data = {
                    FirstName: values.name,
                    LastName: values.surname,
                    Email: values.email,
                    Phone: String(values.phone).replace("(", "").replace(")", "").replaceAll(" ", ""),
                    Password: values.password,
                    IdentityNo: values.identity,
                    dateOfBirth: values.birthDate,
                    referredBy: ""
                };

                const response = await axios.post(formUrl, data);
                toast.success(t('Kayıt işlemi başarıyla tamamlandı. Giriş bilgileriniz e-posta adresinize iletişmiştir.'));

                formik.resetForm();
                setIsSubmitting(false)
            } catch (error) {
                const errorMessage = error.response.data.message;

                if (errorMessage.includes("Phone Number or Email Address")) {
                    toast.error(t('Bu e-posta adresi ya da telefon numarası zaten kullanılıyor. Lütfen bilgileri kontrol edin.'));
                }
                else if (errorMessage.includes("Email already in use !!")) {
                    toast.error(t("Bu e-posta adresi zaten kullanılıyor."))
                }
                else if (errorMessage.includes("Phone Number is already in use ..")) {
                    toast.error(t("Bu telefon numarası zaten kullanılıyor."))
                }
                else if (errorMessage.includes("Unexpected character encountered")) {
                    toast.error(t("Parolanız geçersiz. Lütfen parolanızda en az bir harf ve bir rakam kullanın."))
                }
                else {
                    toast.error(t("Beklenmedik bir hata oluştu. Lütfen tekrar deneyin."))
                }
                setIsSubmitting(false)
            }
        }
    });

    useEffect(() => {
        getApps();
    }, [])

    const getApps = () => {
        const location = window.location.origin
        let url;
        if (location.includes("localhost") || location.includes("test")) {
            url = "https://nova.testmedici.com/api/Global/Apps"
        } else {
            url = `${location}/api/Global/Apps`
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setApp({
                    ios: data.ios,
                    android: data.android
                })
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    useEffect(() => {
        let scaurl = "wt." + window.location.host.replace("www.", "");
        setScaUrl(scaurl)
    }, [])

    return (
        <div className='register-form-content'>
            <div className="container">
                <div className="mt-5">
                    <h3 className='h2 text-center'>{t("register")}</h3>
                    <p className='text-center'>{t("Nova Invest Güçlü Bir Yatırımın Anahtarı!")}</p>
                    <form className='mt-5' onSubmit={formik.handleSubmit}>
                        <div className='row gx-5 justify-content-center register-form-content-mobile'>
                            <div className="col-lg-5">
                                <div className="register-platforms-content shadow-sm">
                                    <h3 className='text-light'>{t("Kullanabileceğiniz Platformlar")}</h3>
                                    <p className='text-light mt-3'>
                                        {t("Hizmetlerimizi kullanarak, mobil cihazlarınız için özel olarak tasarlanmış Android ve iOS uygulamalarımızdan faydalanabilirsiniz. Kullanıcı dostu arayüzlerimiz sayesinde, her an her yerde hızlı ve kolay erişim sağlarken, tüm bu imkanlarla ihtiyaçlarınıza en uygun çözümleri bulabilirsiniz.")}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-center mt-5">
                                        <a href={app.android} target='_blank'>
                                            <div className="register-platforms-content-icon mx-2">
                                                <img src={Android} alt="android" width="40px" />
                                            </div>
                                        </a>
                                        <a href={app.ios} target='_blank'>
                                            <div className="register-platforms-content-icon mx-2">
                                                <img src={Ios} alt="ios" width="38px" />
                                            </div>
                                        </a>
                                        <a href={`https://${scaUrl}`} target='_blank' className='mb-0 text-decoration-none me-2' style={{ fontSize: 15 }}>
                                            <div className="register-platforms-content-icon mx-2">
                                                <img src={WebT} alt="ios" width="46px" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className='row gx-lg-5'>
                                    <div className="col-lg-6">
                                        <div>
                                            <label htmlFor="name">{t("İsminiz")} <span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                id='name'
                                                placeholder={t('İsminizi buraya girin')}
                                                {...formik.getFieldProps('name')}
                                            />
                                            {formik.touched.name && formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-0 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="surname">{t("Soyisminiz")} <span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                id='surname'
                                                placeholder={t('Soyisminizi buraya girin')}
                                                {...formik.getFieldProps('surname')}
                                            />
                                            {formik.touched.surname && formik.errors.surname ? <div className="text-danger">{formik.errors.surname}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="email">{t("E-posta Adresiniz")} <span className='text-danger'>*</span></label>
                                            <input
                                                type="email"
                                                className='form-control'
                                                id='email'
                                                placeholder={t('E-posta adresinizi buraya girin')}
                                                {...formik.getFieldProps('email')}
                                            />
                                            {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="phone">{t("Telefon Numaranız")} <span className='text-danger'>*</span></label>
                                            <MaskedInput
                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                                className='form-control'
                                                id='phone'
                                                placeholder={t('Telefon numaranızı buraya girin')}
                                                {...formik.getFieldProps('phone')}
                                            />
                                            {formik.touched.phone && formik.errors.phone ? <div className="text-danger">{formik.errors.phone}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="password">{t("Parolanız")} <span className='text-danger'>*</span></label>
                                            <input
                                                type="password"
                                                className='form-control'
                                                id='password'
                                                placeholder={t('Parolanızı buraya girin')}
                                                {...formik.getFieldProps('password')}
                                            />
                                            {formik.touched.password && formik.errors.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="repassword">{t("Parola Onayı")} <span className='text-danger'>*</span></label>
                                            <input
                                                type="password"
                                                className='form-control'
                                                id='repassword'
                                                placeholder={t('Parolanızı tekrar girin')}
                                                {...formik.getFieldProps('repassword')}
                                            />
                                            {formik.touched.repassword && formik.errors.repassword ? <div className="text-danger">{formik.errors.repassword}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="birthDate">{t("Doğum Tarihiniz")} <span className='text-danger'>*</span></label>
                                            <input
                                                type="date"
                                                className='form-control'
                                                id='birthDate'
                                                placeholder={t('Doğum tarihinizi girin')}
                                                {...formik.getFieldProps('birthDate')}
                                            />
                                            {formik.touched.birthDate && formik.errors.birthDate ? <div className="text-danger">{formik.errors.birthDate}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt-lg-4 mt-md-0 mt-3">
                                        <div>
                                            <label htmlFor="identity">{t("TC Kimlik Numaranız")}</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                id='identity'
                                                maxLength={11}
                                                placeholder={t('TC kimlik numaranızı girin')}
                                                {...formik.getFieldProps('identity')}
                                            />
                                            {/* {formik.touched.identity && formik.errors.identity ? <div className="text-danger">{formik.errors.identity}</div> : null} */}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <button className='btn btn-primary-v2 w-100 py-2' type='submit' disabled={isSubmitting}>
                                            {isSubmitting ? t("sending") : t("register")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RegisterForm