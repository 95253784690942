import React, { useEffect } from 'react'
import Img from "../../assets/img/trading-platform.png"
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

const TradingPlatform = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className='trading-platform-content'>
            <div className="container">
                <h5 className='h6' data-aos="fade-right">{t("Yatırımlarınızı Yönetmenin Modern Yolu")}</h5>
                <h3 className='h2 mb-3' data-aos="fade-right" data-aos-duration="1500">{t("Sirix İşlem Platformu")}</h3>
                <p className="mb-0" data-aos="fade-right" data-aos-duration="2000">{t("Finansal piyasalarda başarılı bir yatırımcı olmak için doğru araçlara sahip olmak hayati önem taşır. Sirix işlem platformu, modern özellikleri ve kullanıcı dostu arayüzüyle, yatırımcılara etkili bir şekilde varlık yönetme imkanı sunan bir platform olarak öne çıkar.")}</p>
                <div className='trading-platform-image' style={{ backgroundImage: `url(${Img})` }} data-aos="fade-right" data-aos-duration="2500"></div>
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="trading-platform-card shadow-sm h-100" data-aos="fade-up">
                            <div className="trading-platform-card-header d-flex align-items-center justify-content-between">
                                <h5 className='mb-0'>{t("Gelişmiş Teknik Analiz Araçları")}</h5>
                                <span>01</span>
                            </div>
                            <div className="trading-platform-card-body">
                                {t("Sirix, yatırımcılara finansal enstrümanları analiz etmek ve gelecekteki fiyat hareketlerini tahmin etmek için gelişmiş teknik analiz araçları sağlar. Grafik çeşitliliği, göstergeler, çizim araçları ve analiz fonksiyonları, kullanıcıların daha bilinçli ve stratejik yatırım kararları almasını kolaylaştırır.")}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="trading-platform-card shadow-sm h-100" data-aos="fade-up">
                            <div className="trading-platform-card-header d-flex align-items-center justify-content-between">
                                <h5 className='mb-0'>{t("Kolay Kullanım ve Hızlı İşlem")}</h5>
                                <span>02</span>
                            </div>
                            <div className="trading-platform-card-body">
                                {t("Sirix, sezgisel bir arayüzü ve hızlı işlem yürütme özellikleriyle dikkat çeker. Kullanıcılar, tek tıkla işlem yapma, anında emirleri değiştirme ve piyasa koşullarına hızlı bir şekilde tepki gösterme imkanına sahiptirler. Bu özellikler, anlık fiyat değişimlerine hızlı bir şekilde tepki verme yeteneğini artırarak yatırımcılara avantaj sağlar.")}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="trading-platform-card shadow-sm h-100" data-aos="fade-up">
                            <div className="trading-platform-card-header d-flex align-items-center justify-content-between">
                                <h5 className='mb-0'>{t("Çeşitli Varlık Sınıfları")}</h5>
                                <span>03</span>
                            </div>
                            <div className="trading-platform-card-body">
                                {t("Sirix, geniş bir varlık yelpazesi sunar. Forex, hisse senetleri, endeksler, emtialar ve kripto paralar gibi farklı varlık sınıflarında işlem yapma imkanı tanır. Bu çeşitlilik, yatırımcıların portföylerini çeşitlendirmelerine ve farklı piyasalardan faydalanmalarına olanak sağlar.")}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="trading-platform-card shadow-sm h-100" data-aos="fade-up">
                            <div className="trading-platform-card-header d-flex align-items-center justify-content-between">
                                <h5 className='mb-0'>{t("Mobil Uyumlu ve Web Tabanlı")}</h5>
                                <span>04</span>
                            </div>
                            <div className="trading-platform-card-body">
                                {t("Sirix, web tabanlı bir platform olmasıyla bilgisayar kullanıcılarına ek bir avantaj sunarken, mobil uygulama ile de yatırımcılara hareket halindeyken bile piyasayı takip etme ve işlem yapma imkanı verir.")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TradingPlatform