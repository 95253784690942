import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import AboutItems from '../components/About/AboutItems'
import CTAv2 from '../components/CTAv2'
import AboutMissionVision from '../components/About/AboutMissionVision'
import CTA from '../components/CTA'
import { useTranslation } from 'react-i18next'

const About = () => {
    const { t } = useTranslation();
    return (
        <>
            <SubpageBanner title={t("Hakkımızda")} />
            <AboutItems />
            <CTAv2 />
            <AboutMissionVision />
            <CTA />
        </>
    )
}

export default About