import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
    const { t } = useTranslation()
    return (
        <>
            <SubpageBanner title={t("Gizlilik Politikası")} />
            <div className="container mt-5">
                <h3>{t("Gizlilik Politikası")}</h3>
                <p className='mt-4'>{t("Nova Invest olarak, kişisel verilerinizin gizliliğini önemsiyoruz. Web sitemizi ziyaret ettiğinizde ve hizmetlerimizi kullandığınızda bazı bilgilerinizi toplarız. Bu bilgiler, adınız, e-posta adresiniz, telefon numaranız gibi iletişim bilgileriniz, hesap oluştururken kullandığınız kullanıcı adı ve şifre gibi hesap bilgileri ile ödeme işlemleri sırasında paylaştığınız finansal bilgilerdir. Ayrıca, IP adresiniz ve tarayıcı türünüz gibi teknik bilgileri de toplarız.")}</p>
                <p>{t("Topladığımız bu bilgileri, size daha iyi hizmet verebilmek, hesaplarınızı yönetmek, müşteri desteği sağlamak ve yatırım hizmetlerimizi sunmak için kullanıyoruz. Aynı zamanda, yasal yükümlülüklerimizi yerine getirmek amacıyla da bu verilere başvurabiliriz. Web sitemizi ziyaret ettiğinizde, kullanıcı deneyiminizi iyileştirmek ve performansı artırmak için çerezler kullanmaktayız. Çerezler, ziyaret ettiğinizde tarayıcınıza yerleştirilen küçük dosyalardır.")}</p>
                <p>{t("Kişisel verilerinizin güvenliği bizim için çok önemlidir ve bu nedenle verilerinizi korumak için güçlü güvenlik önlemleri alıyoruz. Verileriniz, yalnızca gerekli durumlarda ve yasal çerçevede üçüncü taraflarla paylaşılır. Kişisel verileriniz üzerinde haklarınız bulunmaktadır; bu haklar arasında verilerinize erişme, düzeltilmesini talep etme, silinmesini isteme ve veri işleme faaliyetlerine itiraz etme gibi haklar yer alır.")}</p>
                <p>{t("Gizlilik politikamızla ilgili sorularınız varsa, bize support@nova-markets.com üzerinden ulaşabilirsiniz.")}</p>
            </div>
        </>
    )
}

export default PrivacyPolicy