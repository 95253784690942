import React, { useEffect } from 'react'
import Img1 from "../../assets/img/advantages-1.png"
import Img2 from "../../assets/img/advantages-2.png"
import Img3 from "../../assets/img/advantages-3.png"
import Img4 from "../../assets/img/advantages-4.png"
import Img5 from "../../assets/img/advantages-5.png"
import Img6 from "../../assets/img/advantages-6.png"
import Img7 from "../../assets/img/advantages-7.png"
import AOS from 'aos';
import { useTranslation } from 'react-i18next'

const AdvantagesItems = () => {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className='advantages-content'>
            <div className="container">
                <div className="advantages-item">
                    <div className="row align-items-center justify-content-between advantages-item-mobile">
                        <div className="col-lg-3" data-aos="fade-right">
                            <div className="advantages-item-card">
                                <div className="advantages-item-card-img" style={{ backgroundImage: `url(${Img1})` }}></div>
                            </div>
                        </div>
                        <div className="col-lg-8 position-relative mt-lg-0 mt-md-0 mt-4" data-aos="fade-left">
                            <div className="advantages-item-count">01</div>
                            <h3 className='mb-3'>{t("Deneyimli Ekibimiz")}</h3>
                            <p className="mb-0">{t("Nova Invest, finans dünyasında uzun yıllara dayanan deneyime sahip bir ekip ile ilerlemektedir. Her bir üye, sektördeki güncel gelişmeleri yakından takip ederek yatırımcılara en iyi hizmeti sunma konusunda uzmandır.")}</p>
                        </div>
                    </div>
                </div>
                <div className="advantages-item mt-5">
                    <div className="row align-items-center justify-content-between advantages-item-mobile advantages-item-reverse">
                        <div className="col-lg-8 position-relative mt-lg-0 mt-md-0 mt-4" data-aos="fade-right">
                            <div className="advantages-item-count">02</div>
                            <h3 className='mb-3'>{t("Yatırımcı Odaklı Yaklaşım")}</h3>
                            <p className="mb-0">{t("Yatırımcı memnuniyetini en üst düzeyde tutmayı amaçlıyoruz. Nova Invest olarak, her yatırımcımızın ihtiyaçlarını anlamak ve onlara özel çözümler sunmak için çaba harcıyoruz.")}</p>
                        </div>
                        <div className="col-lg-3" data-aos="fade-left">
                            <div className="advantages-item-card">
                                <div className="advantages-item-card-img" style={{ backgroundImage: `url(${Img2})` }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advantages-item mt-5">
                    <div className="row align-items-center justify-content-between advantages-item-mobile">
                        <div className="col-lg-3" data-aos="fade-right">
                            <div className="advantages-item-card">
                                <div className="advantages-item-card-img" style={{ backgroundImage: `url(${Img3})` }}></div>
                            </div>
                        </div>
                        <div className="col-lg-8 position-relative mt-lg-0 mt-md-0 mt-4" data-aos="fade-left">
                            <div className="advantages-item-count">03</div>
                            <h3 className='mb-3'>{t("Güvenilirlik ve Şeffaflık")}</h3>
                            <p className="mb-0">{t("Nova Invest, güvenilirlik ilkesini benimsemekte ve yatırımcılara şeffaf bir hizmet sunmaktadır. İşlemlerimizde açıklık prensibine dayanarak, yatırımcılaramızın bize tam anlamıyla güvenmelerini sağlıyoruz.")}</p>
                        </div>
                    </div>
                </div>
                <div className="advantages-item mt-5">
                    <div className="row align-items-center justify-content-between advantages-item-mobile advantages-item-reverse">
                        <div className="col-lg-8 position-relative mt-lg-0 mt-md-0 mt-4" data-aos="fade-right">
                            <div className="advantages-item-count">04</div>
                            <h3 className='mb-3'>{t("İnovasyon ve Teknoloji")}</h3>
                            <p className="mb-0">{t("Sektördeki en son teknolojik gelişmeleri takip ediyoruz ve bu teknolojileri kullanarak yatırımcılarımıza daha etkili çözümler sunuyoruz. İnovasyon, Nova Invest'ın temel prensiplerinden biridir.")}</p>
                        </div>
                        <div className="col-lg-3" data-aos="fade-left">
                            <div className="advantages-item-card">
                                <div className="advantages-item-card-img" style={{ backgroundImage: `url(${Img4})` }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advantages-item mt-5">
                    <div className="row align-items-center justify-content-between advantages-item-mobile">
                        <div className="col-lg-3" data-aos="fade-right">
                            <div className="advantages-item-card">
                                <div className="advantages-item-card-img" style={{ backgroundImage: `url(${Img5})` }}></div>
                            </div>
                        </div>
                        <div className="col-lg-8 position-relative mt-lg-0 mt-md-0 mt-4" data-aos="fade-left">
                            <div className="advantages-item-count">05</div>
                            <h3 className='mb-3'>{t("Kişiselleştirilmiş Yatırım Stratejileri")}</h3>
                            <p className="mb-0">{t("Her yatırımcının finansal hedefleri farklıdır. Nova Invest, yatırımcılarının özel ihtiyaçlarını anlayarak kişiselleştirilmiş yatırım stratejileri oluşturur ve bu stratejilerle yatırımcıların başarıya ulaşmasına katkıda bulunur.")}</p>
                        </div>
                    </div>
                </div>
                <div className="advantages-item mt-5">
                    <div className="row align-items-center justify-content-between advantages-item-mobile advantages-item-reverse">
                        <div className="col-lg-8 position-relative mt-lg-0 mt-md-0 mt-4" data-aos="fade-right">
                            <div className="advantages-item-count">06</div>
                            <h3 className='mb-3'>{t("Sosyal Sorumluluk")}</h3>
                            <p className="mb-0">{t("Nova Invest, sadece finansal başarıya odaklanmaz, aynı zamanda toplumsal sorumluluk bilinciyle hareket eder. Sosyal sorumluluk projelerine aktif olarak katılır ve toplum için pozitif bir etki yaratmaya çalışır.")}</p>
                        </div>
                        <div className="col-lg-3" data-aos="fade-left">
                            <div className="advantages-item-card">
                                <div className="advantages-item-card-img" style={{ backgroundImage: `url(${Img6})` }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advantages-item mt-5">
                    <div className="row align-items-center justify-content-between advantages-item-mobile">
                        <div className="col-lg-3" data-aos="fade-right">
                            <div className="advantages-item-card">
                                <div className="advantages-item-card-img" style={{ backgroundImage: `url(${Img7})` }}></div>
                            </div>
                        </div>
                        <div className="col-lg-8 position-relative mt-lg-0 mt-md-0 mt-4" data-aos="fade-left">
                            <div className="advantages-item-count">07</div>
                            <h3 className='mb-3'>{t("Sürekli Gelişim")}</h3>
                            <p className="mb-0">{t("Nova Invest olarak, sürekli olarak kendimizi geliştirme çabası içindeyiz. Sektördeki değişimlere hızla adapte oluyor ve müşterilerimize her zaman en güncel bilgileri ve hizmetleri sunmaya özen gösteriyoruz.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvantagesItems