import React from 'react'
import SubpageBanner from '../components/SubpageBanner'
import { useTranslation } from 'react-i18next'

const Kvkk = () => {
    const { t } = useTranslation()
    return (
        <>
            <SubpageBanner title={t("Kişisel Verilerin Korunması Kanunu")} />
            <div className="container mt-5">
                <h3>{t("Kişisel Verilerin Korunması Kanunu")}</h3>
                <p className='mt-4'>{t("Nova Invest olarak, kişisel verilerinizin güvenliğini sağlamak için 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (KVKK) uygun hareket ediyoruz. KVKK, kişisel verilerin işlenmesi, saklanması ve paylaşılması süreçlerinde uyulması gereken yasal düzenlemeleri belirler ve bireylerin haklarını güvence altına alır.")}</p>
                <p>{t("KVKK kapsamında, Nova Invest tarafından toplanan kişisel veriler, yalnızca gerekli olduğu durumlarda ve hukuki dayanaklara uygun olarak işlenir. Kişisel verilerinizin güvenliğini sağlamak amacıyla, idari ve teknik tedbirler alınarak, yetkisiz erişim, kötüye kullanım, kayıp ve ifşa edilmesine karşı korunmaktadır.")}</p>
                <p>{t("Kişisel verilerinizle ilgili aşağıdaki haklara sahipsiniz:")}</p>
                <ul>
                    <li>{t("Kişisel verilerinizin işlenip işlenmediğini öğrenme,")}</li>
                    <li>{t("İşlenen veriler hakkında bilgi talep etme,")}</li>
                    <li>{t("Verilerinizin düzeltilmesini veya silinmesini talep etme,")}</li>
                    <li>{t("İşlemenin kısıtlanmasını veya verilerinize itiraz etme.")}</li>
                </ul>
                <p>{t("Bu haklarınızı kullanmak için bize support@nova-markets.com üzerinden ulaşabilirsiniz. Talebiniz en kısa sürede değerlendirilerek size yasal süreler içinde geri dönüş yapılacaktır.")}</p>
                <p>{t("KVKK kapsamında, verilerinizin işlenmesi için açık rızanızı almamız gereken durumlarda, tarafınıza gerekli bilgilendirme yapılacak ve onayınız istenecektir. Ayrıca, kişisel verileriniz yalnızca belirlenen amaçlar doğrultusunda ve sınırlı süreyle saklanacak, sürenin dolmasıyla birlikte güvenli bir şekilde imha edilecektir.")}</p>
            </div>
        </>
    )
}

export default Kvkk