import React, { useEffect } from 'react'
import AOS from 'aos';
import CtaImg from "../assets/img/cta-coin.png"
import { useTranslation } from 'react-i18next';

const CTAv2 = () => {
    const {t} = useTranslation();
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div className="container">
            <div className='ctav2-content position-relative' data-aos="zoom-in">
                <div className="row align-items-center h-100">
                    <div className="col-lg-4 text-center position-relative">
                        <div className="ctav2-image">
                            <img src={CtaImg} alt="" width="232px" height="232px" />
                        </div>
                    </div>
                    <div className="col-lg-8 pe-lg-5 pe-md-5 pe-3 h-100">
                        <h5 className='ctav2-text mb-0 d-flex align-items-center h-100'>
                            {t("ctav2text")}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTAv2